@font-face {
  font-family: "TiemposHeadline";
  src: url(TiemposHeadline-Semibold.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "TiemposText";
  src: url(TiemposText-Regular.woff) format("woff");
  font-weight: 500;
  font-style: normal;
}
